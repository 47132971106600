import httpService from '@/modules/common/services/httpService';

const basePath = '/v2/settings';

const getAppsByType = async ({ type, businessUid }) => httpService.get(`${basePath}/get_available_apps_by_type`, { type, buid: businessUid })
  .then((res) => res.data.data)
  .catch((error) => {
    console.error(error);
    return { code: 'error' };
  });

export default {
  getAppsByType,
};
