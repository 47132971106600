<template>
  <div class="notification-pane"
       :class="{'d-flex flex-1 align-center justify-center full-height' : noNotifications || isNotificationsDataLoading,
       'd-flex flex-column full-height' : isAllRead && !noNotifications}">
    <VcLoader v-if="isNotificationsDataLoading" class="d-flex flex-grow-1 align-center notification-loader"/>
    <div class="notification-pane__header d-flex justify-space-between align-center py-4"
         v-if="!noNotifications && !isNotificationsDataLoading">
      <div v-if="$isMobile()" class="ps-6 d-flex align-self-center switch-full-with-text">
        {{ $t('notification_center.badge.show_unread') }}
      </div>
      <VcLink v-else @click="onMarkAll()" class="ps-6"
              :label="$t('notification_center.badge.mark_all')" target="_self"
              url="#" secondary/>
      <VcSwitch
        class="pe-6 switch"
        labelPosition="before"
        @input="onToggleChange()"
        v-model="showOnlyUnread"
        :label="!$isMobile() ? $t('notification_center.badge.show_unread') : ''"
        :color="'var(--v-secondary-base)'"/>
    </div>
    <div class="notification-pane__body" v-if="!noNotifications && !isAllRead && !isNotificationsDataLoading">
      <NotificationItem v-for="notification in notificationsToShow" :key="notification.uid"
                        :data-qa="notification.uid"
                        @click.native="onRowClick(notification)"
                        :title="getNotificationTitle(notification.text_sent)"
                        :description="getNotificationBody(notification.text_sent)"
                        :preview="notification.preview"
                        :timestamp="getNotificationTime(notification)"
                        :unread="!notification.read"
                        :notificationType="notification.notification_type"
                        :avatar-text="notification.avatarText"
                        :avatar-icon="notification.avatarIcon"
                        :is-mobile="$isMobile()"
                        @toggleStatus="onDotClick(notification)"
      />
      <VcInfiniteScroll @onScroll="loadMore"
                        :isEmpty="false"
                        :identifier="infiniteId"
                        :noResultText="$t('notification_center.badge.empty_state.all_notifications')"/>
      <VcEmptyState
        v-if="!isNotificationsDataLoading"
        :image="require('../../assets/last-notifications.svg')"
        title=" "
        :subtitle="$t('notification_center.badge.empty_state.all_notifications')"
        class="empty-state px-6 py-8"
        size="sm"/>
    </div>
    <VcEmptyState v-if="noNotifications"
                  data-qa="empty-state-no-notifications"
                  :image="require('../../assets/empty_notifications.svg')"
                  :title="$t('notification_center.badge.empty_state.no_notifications')"
                  :subtitle="$t('notification_center.badge.empty_state.no_notifications_subtitle')"
                  class="empty-state px-6 py-8"
                  size="md"
    />
    <div class="d-flex flex-grow-1 align-center" v-if="!noNotifications && isAllRead && !isNotificationsDataLoading">
      <VcEmptyState
        data-qa="empty-state-read-all"
        :image="require('../../assets/last-notifications.svg')"
        title=" "
        :subtitle="$t('notification_center.badge.empty_state.read_all')"
        class="empty-state px-6 py-8"
        size="sm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import storageService from '@/modules/common/services/storageService';
import LayoutConstants from '@/constansts/layoutConstants';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import NotificationItem from '../notificationItem/NotificationItem.vue';

export default {
  name: 'NotificationPane',
  components: { NotificationItem },
  data() {
    return {
      showOnlyUnread: false,
      infiniteId: +new Date(),
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('LayoutTopBarStore', ['setTopBarConfig']),
    ...mapActions('NotificationCenterStore', ['fetchNotifications', 'setNotificationReadStatus', 'setAllNotificationsAsRead']),
    ...mapActions('MobileBottomSheetStore', ['setMenuItems']),
    ...mapActions('LayoutBottomNavStore', ['setBottomNavConfig']),
    async init() {
      this.addI18nModule('notifications-pov');
      this.showOnlyUnread = storageService.get('showOnlyUnread') === true;
      this.setTopBarConfig(this.topBarConfig);
      this.setBottomNavConfig({ show: false });
      this.setMenuItems([
        {
          icon: 'icon-Arrow-down-circle',
          label: 'notification_center.badge.mark_all',
          identifier: 0,
          trigger: 'callback',
          payload: () => {
            this.onMarkAll();
          },
        },
        {
          icon: 'icon-Settings',
          label: 'notification_center.settings.notification_settings',
          identifier: 1,
          trigger: 'post',
          payload: { action: LayoutConstants.NAVIGATE_TO, data: { route: 'app.notification_settings' } },
        },
      ]);

      // OnLoad
      this.$track('Notification pane view');
      //  Empty state
      if (this.noNotifications) this.$track('Notification pane  - empty state');
    },
    onToggleChange() {
      const status = this.showOnlyUnread ? 'switch to show only unread' : 'switch to see all notifications';
      this.$track(`Notification pane view - ${status}`);
      storageService.save('showOnlyUnread', this.showOnlyUnread);
      this.infiniteId += 1;
    },
    onMarkAll() {
      this.$track('Notification pane - mark all as read');
      this.setAllNotificationsAsRead();
    },
    onDotClick(notification) {
      const status = notification.read ? 'Notification marked as unread' : 'Notification marked as read';
      this.$track(status, {
        type: notification.notification_type,
        code_name: notification.notification_code_name,
      });
      this.setNotificationReadStatus({
        notification_uid: notification.uid,
        status: !notification.read,
      });
    },
    onDeepLink(notification) {
      if (!notification.deep_link) return;
      this.$track('Notification click', {
        type: notification.notification_type,
        code_name: notification.notification_code_name,
      });
      const [path, queryParams] = this.getQueryParamsFromUrl(notification.deep_link);
      outgoingPostMessageUtils.post('deep-link', { route: notification.deep_link, path, queryParams });
    },
    getQueryParamsFromUrl(deepLinkUrl) {
      const queryParams = {};
      const [path, queryString] = deepLinkUrl.split('?');
      if (queryString) {
        const searchParams = new URLSearchParams(queryString);
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of searchParams.entries()) {
          queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
        }
      }
      return [path, queryParams];
    },
    onRowClick(notification) {
      if (!notification.read) {
        this.setNotificationReadStatus({
          notification_uid: notification.uid,
          status: true,
        });
      }
      this.onDeepLink(notification);
      this.$emit('onNotificationRowClicked');
    },
    getNotificationTime(notification) {
      if (!notification.created_at) return undefined;
      return new Date(notification.created_at).getTime();
    },
    getNotificationTitle(text) {
      if (text && text.title) return text.title;
      return '';
    },
    getNotificationBody(text) {
      if (text && text.body) return text.body;
      return '';
    },
    async loadMore($state) {
      await this.fetchNotifications();
      if (this.notificationsLoadCompleted) {
        $state.complete();
      } else {
        $state.loaded();
      }
    },
  },
  computed: {
    ...mapGetters('NotificationCenterStore', ['notifications', 'unreadNotifications', 'notificationsLoadCompleted', 'isNotificationsDataLoading']),
    ...mapGetters('CommonStore', ['isMobile']),
    topBarConfig() {
      return {
        title: 'notification_center.topbar.title',
        showBackButton: true,
        firstButton: {
          identifier: 1,
          icon: 'icon-dots-three-vertical',
          actionEvent: 'MobileBottomSheetStore/setBottomSheetState',
          dispatchActionType: 'pov',
          data: true,
          payload: {
            action: 'updateStore',
          },
        },
        show: true,
      };
    },
    notificationsToShow() {
      if (this.showOnlyUnread) return this.unreadNotifications;
      return this.notifications;
    },
    isAllRead() {
      return this.showOnlyUnread && this.unreadNotifications.length === 0;
    },
    noNotifications() {
      return (!this.isNotificationsDataLoading && !this.notifications?.length);
    },
    shouldShowPov() {
      return ((this.$hasFeature('new_layout_pov') && this.isMobile) || !this.isMobile);
    },
  },
};
</script>

<style scoped lang="scss">
.notification-pane {
  width: 100%;
  &.full-height {
    height: 100%;
  }

  .mark-all {
    cursor: pointer;
    font-size: 13px;
    color: var(--gray-darken-4);
  }

  ::v-deep .switchContainer .VcSwitch label {
    font-size: 13px;
    color: var(--gray-darken-4);
  }

  .switch-full-with-text {
    font-size: 13px;
    color: var(--gray-darken-4);
  }

  ::v-deep .empty-state .VcEmptyStateImage {
    margin-bottom: var(--size-value3);
  }

  .notification-loader {
    height: 100%;
    margin: auto;
  }
}
</style>
