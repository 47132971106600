import clientService from '@/modules/client/services/clientService';
import Vue from 'vue';

const state = {
  clients: {},
  vaultedCards: {},
  clientResults: {},
  statuses: {},
};

const getters = {
  client: (state) => (uid) => state.clients[uid],
  clientVaultedCards: (state) => (clientUid) => state.vaultedCards[clientUid],
  clientResults: (state) => (view) => state.clientResults[view]?.results,
  clientResultsCount: (state) => (view) => state.clientResults[view]?.count,
  statusById: (state) => (id) => state.statuses[id],
  statuses: (state) => state.statuses,
};

const mutations = {
  setClient(state, client) {
    state.clients = { ...state.clients, [client.uid]: client };
  },
  setVaultedCards(state, payload) {
    state.vaultedCards = { ...state.vaultedCards, [payload.clientUid]: payload.cards };
  },
  setClientResults(state, payload) {
    state.clientResults = {
      ...state.clientResults,
      [payload.viewUid]: {
        results: payload.data.clients,
        count: payload.data.count,
      },
    };
  },
  removeViewFromClientResults(state, uid) {
    Vue.delete(state.clientResults, uid);
  },
  resetClientResults(state) {
    state.clientResults = {};
  },
  setStatuses(state, data) {
    const statuses = [...data.statuses, ...data.custom_statuses];
    state.statuses = statuses.reduce((a, v) => ({ ...a, [v.id]: v }), {});
  },
  addTagsToClientResult(state, { uid, tags }) {
    let client = {};
    Object.keys(state.clientResults).forEach((view) => {
      client = state.clientResults[view].results.find((c) => c.matter_uid === uid);
      tags.forEach((tag) => client.tag_list.push(tag));
    });
  },
};

const actions = {
  async getClient({ commit }, uid) {
    const client = await clientService.fetchClient(uid);
    commit('setClient', client);
  },
  async getVaultedCards({ commit }, clientUid) {
    const cards = await clientService.fetchClientVaultedCards(clientUid);
    commit('setVaultedCards', { clientUid, cards });
  },
  async fetchClients({ commit, rootGetters }, payload) {
    const {
      viewUid,
      perPage,
      page,
      query,
      sortBy,
      sortOrder,
      filter,
      setClients,
    } = payload;

    try {
      const useNewDecoration = rootGetters['BusinessStore/hasFeature']('crm_custom_fields');
      const currentViewClientResults = getters.clientResults(state)(viewUid) || [];
      if (!setClients && currentViewClientResults.length) return { existingData: true };

      const data = await clientService.getClients(perPage, page, query, sortBy, sortOrder, filter, useNewDecoration);
      data.clients = data.clients.map((client) => ({ ...client, tag_list: client.tag_list ? client.tag_list : client.tags.split(' ').filter((tag) => tag) }));
      commit('setClientResults', { viewUid, data });
      return { existingData: false, count: data.count };
    } catch (error) {
      const res = { error };
      console.error(error);
      return res;
    }
  },
  resetClients({ commit }) {
    commit('resetClientResults');
  },
  async fetchStatuses({ commit }) {
    const statuses = await clientService.getStatuses();
    commit('setStatuses', statuses);
  },
  addTagsToClient({ commit }, { matterUids, tags }) {
    matterUids.forEach((uid) => {
      commit('addTagsToClientResult', { uid, tags });
    });
  },
  async createNote({ dispatch }, payload) {
    const createNoteResponse = await clientService.createNote(payload);
    dispatch('getClient', createNoteResponse?.data?.client?.uid);
    return createNoteResponse;
  },
  removeViewFromClientsResult({ commit }, uid) {
    commit('removeViewFromClientResults', uid);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
