import saleService from '@/modules/payments/services/saleService';
import {
  CHECKOUT_ACTIONS,
  CHECKOUT_ITEM_TYPES,
  DISCOUNT_TYPES,
} from '@/modules/payments/constants/pos';
import { i18n } from '@/plugins/i18n';
import businessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import taxUtil from '@/modules/payments/utils/taxUtil';

const initialState = {
  matterUid: null,
  billableItems: [],
  discount: { type: DISCOUNT_TYPES.fixed, value: 0 },
  newProducts: [],
};

const state = { ...initialState };

const helpers = {
  getPriceBreakdown(checkoutItems, discount, defaultCurrency) {
    const currency = checkoutItems[0]?.currency ?? defaultCurrency;
    const netTotal = checkoutItems.reduce((acc, item) => acc + item.netTotal, 0);
    const taxTotal = checkoutItems.reduce((acc, item) => acc + item.taxTotal, 0);
    let discountValue = discount.value;
    if (discount.type === DISCOUNT_TYPES.percentage) {
      discountValue = ((netTotal + taxTotal) * discount.value) / 100;
    }
    const grandTotal = netTotal + taxTotal - discountValue;

    return {
      currency,
      netTotal,
      taxTotal,
      discountValue,
      grandTotal,
      discount,
    };
  },
  getClientDetails(client, matter) {
    if (!client || !matter) {
      return initialState.clientDetails;
    }

    const matterName = matter.display_name === client.full_name ? null : matter.display_name;

    return {
      client_uid: client.uid,
      matter_uid: matter.uid,
      clientName: client.full_name,
      matterName,
      avatar: {
        size: 'sm',
        imagePath: client.photo_path,
        colorId: client.color_id,
      },
    };
  },
  getTaxesAmount(taxes) {
    let taxesAmount = 0;
    taxes.forEach((tax) => {
      taxesAmount += tax.tax_total;
    });
    return taxesAmount;
  },
  getCheckoutItemData({ uid, type, ...rest }, rootGetters) {
    const taxMode = rootGetters['PaymentSettingsStore/taxMode'];
    let item = null;
    switch (type) {
      case CHECKOUT_ITEM_TYPES.ClientBookingPackage: {
        const clientPackage = rootGetters['ClientPackageStore/getClientPackageByUid'](uid);
        item = helpers.getClientBookingPackageData(clientPackage);
        break;
      }
      case CHECKOUT_ITEM_TYPES.ProductOrder: {
        const productOrder = rootGetters['ProductOrderStore/getProductOrderByUid'](uid);
        const product = rootGetters['ProductsStore/getProductByUid'](productOrder.product_id);
        item = helpers.getProductOrderData(productOrder, product);
        break;
      }
      case CHECKOUT_ITEM_TYPES.Meeting: {
        const appointment = rootGetters['AppointmentStore/getAppointmentByUid'](uid);
        const service = rootGetters['ServicesStore/getNoDiscussServiceByUid'](appointment.service_id);
        item = helpers.getMeetingsData(appointment, service);
        break;
      }
      case CHECKOUT_ITEM_TYPES.EventAttendance: {
        const eventAttendance = rootGetters['EventAttendanceStore/getEventAttendanceByUid'](uid);
        const service = rootGetters['ServicesStore/getNoDiscussServiceByUid'](eventAttendance.event_service_uid);
        item = helpers.getEventAttendanceData(eventAttendance, service);
        break;
      }
      case CHECKOUT_ITEM_TYPES.Service: {
        const service = rootGetters['ServicesStore/getNoDiscussServiceByUid'](uid);
        item = helpers.getServiceData(service, taxMode);
        break;
      }
      case CHECKOUT_ITEM_TYPES.Product: {
        let product = state.newProducts.find((elem) => elem.id === uid);
        if (product === null || product === undefined) {
          product = rootGetters['ProductsStore/getProductByUid'](uid);
        }
        item = helpers.getProductData(product, taxMode);
        break;
      }
      default:
        item = {};
        break;
    }
    return {
      ...item,
      uid,
      type,
      ...rest,
    };
  },
  getEntityDescription(entityType, entity) {
    switch (entityType) {
      case CHECKOUT_ITEM_TYPES.EventAttendance:
      case CHECKOUT_ITEM_TYPES.Meeting: {
        const formattedDate = businessDateFormats.dateNoCurrentYear(entity.start_time || entity.real_start_time);
        return i18n.t('point_of_sale.checkout_items.meeting_description', { formattedDate })
          + (entity.description ? `\n${entity.description}` : '');
      }
      case CHECKOUT_ITEM_TYPES.ProductOrder:
      case CHECKOUT_ITEM_TYPES.ClientBookingPackage: {
        const formattedDate = entity.created_at_h || businessDateFormats.dateNoCurrentYear(entity.created_at);
        return i18n.t('point_of_sale.checkout_items.general_description', { formattedDate })
          + (entity.description ? `\n${entity.description}` : '');
      }
      default:
        return null;
    }
  },
  getDatetimeInfo(entityType, entity) {
    switch (entityType) {
      case CHECKOUT_ITEM_TYPES.EventAttendance:
      case CHECKOUT_ITEM_TYPES.Meeting: {
        const formattedDate = businessDateFormats.dateNoCurrentYear(entity.start_time || entity.real_start_time);
        const formattedStartTime = businessDateFormats.time(entity.start_time || entity.real_start_time);
        const formattedEndTime = businessDateFormats.time(entity.end_time || entity.real_end_time);
        const formattedDuration = businessDateFormats.duration(entity.duration);
        return i18n.t('point_of_sale.checkout_items.meeting_datetime_info', {
          date: formattedDate,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          duration: formattedDuration,
        });
      }
      default:
        return null;
    }
  },
  getClientBookingPackageData(clientPackage) {
    const description = helpers.getEntityDescription(CHECKOUT_ITEM_TYPES.ClientBookingPackage, clientPackage);
    const taxes = clientPackage.taxes || [];
    const taxTotal = helpers.getTaxesAmount(taxes);
    const netTotal = Number(clientPackage.net_price);
    const grandTotal = netTotal + taxTotal;

    return {
      entity_uid: clientPackage.id,
      entity_type: CHECKOUT_ITEM_TYPES.ClientBookingPackage,
      matter_uid: clientPackage.conversation_id,
      name: clientPackage.name,
      description,
      quantity: 1,
      currency: clientPackage.currency,
      netTotal,
      grandTotal,
      taxTotal,
      taxes,
      image: clientPackage.image_path,
    };
  },
  getProductOrderData(productOrder, product) {
    const description = helpers.getEntityDescription(CHECKOUT_ITEM_TYPES.ProductOrder, productOrder);
    const taxes = productOrder.taxes || [];
    const taxTotal = helpers.getTaxesAmount(taxes);
    const grandTotal = Number(productOrder.total_price);
    const netTotal = grandTotal - taxTotal;

    return {
      entity_uid: productOrder.id,
      entity_type: CHECKOUT_ITEM_TYPES.ProductOrder,
      matter_uid: productOrder.matter_uid,
      name: productOrder.name,
      description,
      quantity: 1,
      currency: productOrder.currency,
      netTotal,
      grandTotal,
      taxTotal,
      taxes,
      image: product?.image_url,
    };
  },
  getMeetingsData(appointment, service) {
    const description = helpers.getEntityDescription(CHECKOUT_ITEM_TYPES.Meeting, appointment);
    const taxes = appointment.payment_status?.taxes || [];
    const taxTotal = helpers.getTaxesAmount(taxes);
    const netTotal = Number(appointment.payment_status?.net_price || 0);
    const grandTotal = netTotal + taxTotal;

    return {
      entity_uid: appointment.id,
      entity_type: CHECKOUT_ITEM_TYPES.Meeting,
      matter_uid: appointment.matter_uid,
      name: appointment.title,
      description,
      quantity: 1,
      currency: appointment.currency,
      netTotal,
      grandTotal,
      taxTotal,
      taxes,
      image: appointment.image_url ?? service?.image_path,
      linked_uid: appointment.linked_booking_uid,
      datetime_info: helpers.getDatetimeInfo(CHECKOUT_ITEM_TYPES.Meeting, appointment),
    };
  },
  getEventAttendanceData(eventAttendance, service) {
    const description = helpers.getEntityDescription(CHECKOUT_ITEM_TYPES.EventAttendance, eventAttendance);
    const taxes = eventAttendance.taxes || [];
    const taxTotal = helpers.getTaxesAmount(taxes);
    const netTotal = Number(eventAttendance.net_price);
    const grandTotal = netTotal + taxTotal;

    return {
      entity_uid: eventAttendance.id,
      entity_type: CHECKOUT_ITEM_TYPES.EventAttendance,
      matter_uid: eventAttendance.matter.uid,
      name: eventAttendance.event_title,
      description,
      quantity: 1,
      currency: eventAttendance.currency,
      netTotal,
      grandTotal,
      taxTotal,
      taxes,
      image: eventAttendance.image_url ?? service?.image_path,
    };
  },
  getServiceData(service, taxMode) {
    const taxes = service.taxes || [];
    const {
      netTotal,
      taxTotal,
      grandTotal,
    } = taxUtil.calcTaxModeTotals(taxMode, Number(service.price), taxes);

    return {
      entity_uid: service.id,
      entity_type: CHECKOUT_ITEM_TYPES.Service,
      name: service.name,
      description: service.description,
      quantity: 1,
      currency: service.currency,
      netTotal,
      grandTotal,
      taxTotal,
      taxes,
      image: service.image_path,
    };
  },
  getProductData(product, taxMode) {
    try {
      const taxes = product.taxes || [];
      const {
        netTotal,
        taxTotal,
        grandTotal,
      } = taxUtil.calcTaxModeTotals(taxMode, Number(product.price), taxes);

      return {
        entity_uid: product.id,
        entity_type: CHECKOUT_ITEM_TYPES.Product,
        name: product.name,
        description: product.description,
        quantity: 1,
        currency: product.currency,
        netTotal,
        grandTotal,
        taxTotal,
        taxes,
        image: product.image_url,
      };
    } catch (e) {
      console.log(e);
    }
    return {};
  },
};

const mutations = {
  setMatterUid(state, matterUid) {
    state.matterUid = matterUid;
  },
  setBillableItems(state, billableItems) {
    state.billableItems = billableItems;
  },
  addBillableItem(state, billableItem) {
    state.billableItems = [...state.billableItems, billableItem];
  },
  removeBillableItem(state, index) {
    state.billableItems = state.billableItems.filter((_, i) => i !== index);
  },
  updateBillableItem(state, { index, billableItem }) {
    const newBillableItem = {
      ...state.billableItems[index],
      ...billableItem,
    };
    state.billableItems = state.billableItems.with(index, newBillableItem);
  },
  removeMultipleBillableItems(state, indexes) {
    state.billableItems = state.billableItems.filter((_, i) => !indexes.includes(i));
  },
  setDiscount(state, discount) {
    state.discount = discount;
  },
  clearData(state) {
    Object.assign(state, initialState);
  },
  addNewProduct(state, product) {
    state.newProducts = [...state.newProducts, product];
  },
};

const actions = {
  async setMatterUid({ commit, dispatch, rootGetters }, matterUid) {
    let matter = rootGetters['MatterStore/matterByUid'](matterUid);
    if (!matter && matterUid) {
      await dispatch('MatterStore/getMatter', matterUid, { root: true });
      matter = rootGetters['MatterStore/matterByUid'](matterUid);
    }

    const clientUid = matter?.contacts[0]?.uid;
    const client = rootGetters['ClientStore/client'](clientUid);
    if (!client && clientUid) {
      await dispatch('ClientStore/getClient', clientUid, { root: true });
    }

    commit('setMatterUid', matterUid);
  },
  async setBillableItemsAndFetchData({ getters, dispatch }, { billableItems, replaceList = true }) {
    if (getters.selectedMatter && billableItems.length) {
      const uidsByType = billableItems.reduce((acc, item) => {
        acc[item.type] ??= [];
        acc[item.type].push(item.uid);
        return acc;
      }, {});

      const promises = Object.entries(uidsByType).map(([type, uids]) => {
        switch (type) {
          case CHECKOUT_ITEM_TYPES.ClientBookingPackage:
            return dispatch('ClientPackageStore/fetchClientPackages', { uids }, { root: true });
          case CHECKOUT_ITEM_TYPES.ProductOrder:
            return dispatch('ProductOrderStore/fetchProductOrders', { uids }, { root: true });
          case CHECKOUT_ITEM_TYPES.Meeting:
            return dispatch('AppointmentStore/fetchAppointments', {
              uids,
              matterUid: getters.selectedMatter?.uid,
              clientUid: getters.selectedClient?.uid,
            }, { root: true });
          case CHECKOUT_ITEM_TYPES.EventAttendance:
            return dispatch('EventAttendanceStore/fetchEventAttendances', {
              uids,
              matterUid: getters.selectedMatter?.uid,
              clientUid: getters.selectedClient?.uid,
            }, { root: true });
          default:
            return null;
        }
      });

      await Promise.all(promises);
    }

    if (replaceList) {
      dispatch('setBillableItems', billableItems);
    } else {
      billableItems.forEach((item) => {
        dispatch('addBillableItem', item);
      });
    }
  },
  setBillableItems({ commit }, billableItems) {
    commit('setBillableItems', billableItems);
  },
  addBillableItem({ commit }, billableItem) {
    commit('addBillableItem', billableItem);
  },
  removeBillableItem({ commit }, index) {
    commit('removeBillableItem', index);
  },
  updateBillableItem({ commit }, { index, billableItem }) {
    commit('updateBillableItem', { index, billableItem });
  },
  removeLinkedBillableItems({ commit, getters }, linkedItems) {
    const indexes = [];
    linkedItems.forEach((linkedItem) => {
      indexes.push(getters.checkoutItems.findIndex((i) => i.entity_uid === linkedItem.uid && i.entity_type === linkedItem.type));
    });
    commit('removeMultipleBillableItems', indexes);
  },
  setDiscount({ commit }, discount) {
    commit('setDiscount', discount);
  },
  clearData({ commit }) {
    commit('clearData');
  },
  async createSaleAndTakePayment({ getters, rootGetters }, checkoutAction) {
    const { currency } = getters.checkoutItems[0];
    const taxMode = rootGetters['PaymentSettingsStore/taxMode'];
    const saleItems = getters.checkoutItems.map((item) => ({
      entity_uid: item.entity_uid,
      entity_type: item.entity_type,
      entity_name: item.name,
      amount: taxUtil.getPriceByTaxMode(taxMode, item.netTotal, item.grandTotal),
      taxes: item?.taxes,
    }));
    const sale = await saleService.createSale(getters.selectedMatter.uid, currency, saleItems);

    if (checkoutAction === CHECKOUT_ACTIONS.FreeCheckout) {
      await saleService.completeSale(sale.uid, { new_api: true });
    }
    return sale;
  },
  addNewProduct({ commit }, product) {
    commit('addNewProduct', product);
  },
};

const getters = {
  // eslint-disable-next-line no-shadow
  selectedMatter: (state, getters, rootState, rootGetters) => rootGetters['MatterStore/matterByUid'](state.matterUid),
  // eslint-disable-next-line no-shadow
  selectedClient: (state, getters, rootState, rootGetters) => rootGetters['ClientStore/client'](getters.selectedMatter?.contacts[0]?.uid),
  // eslint-disable-next-line no-shadow
  clientDetails: (state, getters) => helpers.getClientDetails(getters.selectedClient, getters.selectedMatter),
  // eslint-disable-next-line no-shadow
  checkoutItems: (state, getters, rootState, rootGetters) => state.billableItems
    .map((item) => helpers.getCheckoutItemData(item, rootGetters)),
  // eslint-disable-next-line no-shadow
  priceBreakdown: (state, getters, rootState, rootGetters) => helpers.getPriceBreakdown(getters.checkoutItems, state.discount, rootGetters['PaymentSettingsStore/currency']),
  // eslint-disable-next-line no-shadow
  getCountByUidAndType: (state) => (uid, type) => state.billableItems.filter((item) => item.uid === uid && item.type === type).length,
  // eslint-disable-next-line no-shadow
  filterByCurrency: (state, getters, rootState, rootGetters) => {
    const currency = rootGetters['PaymentSettingsStore/currency'];
    return (array) => array.filter((item) => item.currency === currency);
  },
  // eslint-disable-next-line no-shadow
  getLinkedItems: (state, getters) => (item) => {
    if (!item?.linked_uid) {
      return [];
    }
    return getters.checkoutItems.filter((i) => i.linked_uid === item.linked_uid);
  },
  newProducts: (state) => state.newProducts,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
