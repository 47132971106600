import businessService from '@/modules/business/services/businessService';

const state = {
  admin_info: {
    firstName: '',
    lastName: '',
  },
  address: '',
  phone: '',
};

const getters = {
  firstName: (state) => state.admin_info.firstName,
  lastName: (state) => state.admin_info.lastName,
  phone: (state) => state.phone,
  address: (state) => state.address,
  adminInfo: (state) => state.admin_info,
};

const mutations = {
  setAdminInfo(state, adminInfo) {
    state.admin_info = adminInfo;
  },
  setAddress(state, address) {
    state.address = address;
  },
  setPhone(state, phone) {
    state.phone = phone;
  },
};

const actions = {
  async fetchBusinessInfo({ commit, rootGetters }) {
    try {
      const businessUid = rootGetters['AuthStore/businessUid'];
      const { business } = await businessService.fetchBusiness(businessUid);
      if (business) {
        const adminAccount = business.admin_account;
        commit('setAddress', business.business?.address);
        commit('setPhone', business.business?.phone);
        commit('setAdminInfo', { firstName: adminAccount?.first_name, lastName: adminAccount?.last_name });
      }
    } catch (e) {
      console.error('error', e);
    }
  },
  async setBusinessInfo({ rootGetters, commit }, payload) {
    try {
      const businessUid = rootGetters['AuthStore/businessUid'];
      const business = await businessService.updateBusiness(businessUid, payload);

      const adminAccount = business?.owner;
      commit('setAddress', business?.address);
      commit('setPhone', business?.phone_info);
      commit('setAdminInfo', { firstName: adminAccount?.first_name, lastName: adminAccount?.last_name });
      return business;
    } catch (e) {
      console.error('error', e);
      return e;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
