<template>
  <v-app :class="{'rtl': isRtl}"  id="app">
    <SystemNotificationsContainer :class="{'blurred': blurredLayout}" v-if="!isMobile"/>
    <VcLayout class="app-layout">
      <VcOverlay :color="isVueFullscreen ? 'black' : 'transparent'"
                 :value="isOverlayDisplayed || isVueFullscreen"/>
      <AngularDelegator v-if="isInitComplete"/>
      <VcLayout :class="{'blurred': blurredLayout}" class="pov-layout">
        <MenuContainer class="menu-container pointer-events-all"
                       :class="{'wide-menu-container': !isMobile}"
                       :is-loading="isLayoutLoading"
                       :is-actions-disabled="isLayoutActionsDisabled"/>
        <VcLayout column>
          <AiGeneratorAngularAdapter/>
          <TopBarContainer v-if="isInitComplete"
                           class="top-bar pointer-events-all"
                           :class="{'desktop': !isMobile}"
                           :is-loading="isLayoutLoading"
                           :is-actions-disabled="isLayoutActionsDisabled"
                           :bizaiAvailable="bizaiAvailable">
          </TopBarContainer>
          <BizAI v-if="bizaiAvailable && !isMobile && isBrandingLoaded"></BizAI>
          <router-view class="content-area"
                       :class="{'pointer-events-none': isAngularDisplayed,
                       'pov-content': !isAngularDisplayed,
                       'mobile-display': isMobile,
                       'show-bottom-nav': isMobile && showBottomNav}"/>
        </VcLayout>
        <ToastContainer/>
        <CommonModals/>
        <SessionTimoutModal v-if="isSessionPromptVisible"/>
        <SidePane v-if="hasSidePaneFF && isAngularLoaded"/>
        <BottomNavContainer v-if="isMobile" class="bottom-bar pointer-events-all"/>
        <MobileBottomSheet v-if="isMobile"/>
        <Transition>
          <MainLoader v-if="!featureFlagsLoaded && !isBrandingLoaded"/>
        </Transition>
      </VcLayout>
    </VcLayout>
  </v-app>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import TopBarContainer from '@/modules/layout/topBar/components/TopBarContainer.vue';
import MenuContainer from '@/modules/layout/mainMenu/components/MenuContainer.vue';
import AngularDelegator from '@/modules/angularDelegator/components/AngularDelegator.vue';
import BottomNavContainer from '@/modules/layout/bottomNav/components/BottomNavContainer.vue';
import colorUtil from '@vcita/design-system/utils/colorUtil';
import ToastContainer from '@/modules/layout/toast/components/ToastContainer.vue';
import { isMobileLayout } from '@/utils/mobile-detection';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import faviconUtil from '@/utils/faviconUtil';
import SidePane from '@/modules/sidePane/components/SidePane.vue';
import MobileBottomSheet from '@/modules/layout/mobileBottomSheet/components/MobileBottomSheet.vue';
import SystemNotificationsContainer
  from '@/modules/layout/systemNotifications/components/SystemNotificationsContainer.vue';
import SessionTimoutModal
  from '@/modules/layout/sessionTimeoutModal/components/SessionTimoutModal.vue';
import BizAI from '@/modules/bizai/components/BizAI.vue';
import MainLoader from '@/modules/layout/mainLoader/MainLoader.vue';
import CommonModals from '@/modules/layout/commonModals/components/CommonModals.vue';
import AiGeneratorAngularAdapter
  from '@/modules/aiContentGenerator/components/AiGeneratorAngularAdapter.vue';

export default {
  name: 'MainApp',
  components: {
    CommonModals,
    AiGeneratorAngularAdapter,
    MainLoader,
    MobileBottomSheet,
    ToastContainer,
    TopBarContainer,
    MenuContainer,
    BottomNavContainer,
    AngularDelegator,
    SidePane,
    SystemNotificationsContainer,
    SessionTimoutModal,
    BizAI,
  },
  async created() {
    //  Check First if Authenticated
    this.getToken().then(() => {
      if (this.isAuthenticated) {
        this.setIsInitComplete(true);
        this.setIsMobile(isMobileLayout());

        Promise.all([
          this.fetchBranding(),
          this.fetchFeatureFlags(),
          this.fetchAttributes(),
        ]).then(() => {
          this.initExtendSession();
          this.forceWLDomain();

          if (this.$featureFlagsLoaded()) {
            this.checkLayoutActionsDisabled();
            this.setFavicon();
          }
          this.setIsRtl(this.$i18n.locale === 'he');

          this.fetchInitialData().then(() => {
            this.handleQueryParams();
            this.setIsInitialDataLoaded(true);
          });
        });
      } else {
        this.redirectToLogin();
      }
    });
  },
  mounted() {
    this.addEssentialI18nModule('common-pov');
    setTimeout(this.calcTime, 1000);
    this.updatePrimaryThemeColor(this.primaryColor);
    this.updateSecondaryThemeColor(this.secondaryColor);
    this.updateTextThemeColor(this.textColor);
  },
  computed: {
    ...mapGetters('CommonStore', ['isMobile', 'isMobileApp', 'isInitComplete', 'isRtl']),
    ...mapGetters('AuthStore', ['isAuthenticated', 'isSessionPromptVisible']),
    ...mapGetters('MainMenuStore', ['shouldShowMenu', 'isMenuTranslationLoading', 'allMenuItemsLoaded']),
    ...mapGetters('AngularStore', ['isAngularLoaded', 'isAngularDisplayed', 'isOverlayDisplayed', 'isVueFullscreen', 'blurredLayout']),
    ...mapGetters('BusinessStore', ['hasFeature', 'primaryColor', 'secondaryColor', 'textColor', 'featureFlagsLoaded', 'isBrandingLoaded']),
    ...mapGetters('LayoutBottomNavStore', ['showBottomNav']),
    ...mapGetters('LayoutStore', ['isLayoutActionsDisabled']),
    ...mapGetters('StaffStore', ['staffDataLoaded', 'staffUid', 'staffData']),
    isMobile() {
      return isMobileLayout() || this.isMobileApp;
    },
    hasSidePaneFF() {
      return this.hasFeature('side_pane');
    },
    isLayoutLoading() {
      return !this.staffDataLoaded || this.isMenuTranslationLoading || !this.allMenuItemsLoaded;
    },
    bizaiAvailable() {
      return this.hasFeature('bizai_chat_enabled');
    },
  },
  methods: {
    ...mapActions('CommonStore', ['setIsInitComplete', 'setIsMobile', 'fetchInitialData', 'setIsRtl']),
    ...mapActions('BusinessStore', ['fetchBranding', 'fetchFeatureFlags', 'fetchAttributes']),
    ...mapActions('AuthStore', ['redirectToLogin', 'getToken', 'forceWLDomain', 'initExtendSession']),
    ...mapActions('SidePaneStore', { openSidePane: 'newPane' }),
    ...mapActions(['setIsInitialDataLoaded']),
    ...mapActions('LayoutStore', ['checkLayoutActionsDisabled']),
    ...mapActions('ExternalScriptsStore', ['setFullstoryData']),
    calcTime() {
      const style = 'color: #0093b8;font-size: 40px;text-shadow: 1px 1px 5px #c2e5ee;filter: dropshadow(color=#c2e5ee, offx=1, offy=1)';
      console.log('%cpov', style);
      const entries = performance.getEntriesByType('navigation');
      entries.forEach((entry) => {
        console.log(`POV load time: ${(entry.domContentLoadedEventEnd / 1000).toFixed(2)}sec`);
      });
    },
    updatePrimaryThemeColor(color) {
      this.$vuetify.theme.themes.light.primary = colorUtil.rgbToHex(color);
    },
    updateSecondaryThemeColor(color) {
      this.$vuetify.theme.themes.light.secondary = {
        base: colorUtil.rgbToHex(color),
        lighten1: colorUtil.pSBC(0.6, colorUtil.rgbToHex(color)),
        lighten2: colorUtil.pSBC(0.76, colorUtil.rgbToHex(color)),
        lighten3: colorUtil.pSBC(0.93, colorUtil.rgbToHex(color)),
      };
    },
    updateTextThemeColor(color) {
      this.$vuetify.theme.themes.light.text = colorUtil.rgbToHex(color);
    },
    initGetProgressSteps() {
      outgoingPostMessageUtils.post('getChecklistProgress');
    },
    setFavicon() {
      let favicon = window.pov_config.VUE_APP_ENV || 'development';
      if (this.hasFeature('use_specific_favicon')) {
        favicon = this.hasFeature('star_favicon') ? 'star' : 'other';
      }
      faviconUtil.handleFavicon(favicon);
    },
    handleQueryParams() {
      if (this.$route.query.sp_type && this.$route.query.sp_uid) {
        this.openSidePane({ type: this.$route.query.sp_type, uid: this.$route.query.sp_uid });
      }
    },
    updateFullstoryWithStaffData() {
      const displayName = this.staffData.display_name;
      const { email } = this.staffData;
      this.setFullstoryData({ uid: this.staffUid, properties: { displayName, email } });
    },
  },
  watch: {
    primaryColor(color) {
      this.updatePrimaryThemeColor(color);
    },
    secondaryColor(color) {
      this.updateSecondaryThemeColor(color);
    },
    textColor(color) {
      this.updateTextThemeColor(color);
    },
    isAngularLoaded() {
      this.initGetProgressSteps();
    },
    isRtl(isRtl) {
      this.$vuetify.rtl = isRtl;
    },
    staffDataLoaded(staffDataLoaded) {
      if (staffDataLoaded) {
        this.updateFullstoryWithStaffData();
      }
    },
    $route() {
      this.handleQueryParams();
    },
  },
};

</script>

<style lang="scss" scoped>
@import "@vcita/design-system/src/scss/mixins.scss";

$bottom_bar_height: 48px;

html {
  overflow: hidden;
}

html, body {
  height: 100%;
  @supports (height: 100dvh) {
    height: unset;
  }
}

#app {
  height: 100%;
  @supports (height: 100dvh) {
    height: 100dvh;
    ::v-deep .v-application--wrap {
      min-height: fit-content;
      overflow-y: hidden;
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .app-layout{
    position: relative;
    height: 100%;
    .pov-layout {
      text-align: center;
    }
  }
}

.blurred {
  filter: blur(5px);
}

.wide-menu-container {
  height: 100%;
}

.content-area {
  position: relative;
  width: 100%;
  flex: 1 1 0;

  &.mobile-display {
    margin-bottom: $bottom_bar_height;

    &.pov-content {
      margin-top: var(--vc-top-bar-height);
    }
  }

  &.pov-content {
    overflow-y: auto;
  }

  &.show-bottom-nav {
    max-height: calc(100dvh - (var(--vc-top-bar-height) + $bottom_bar_height));
  }
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.bottom-bar {
  height: $bottom_bar_height;
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.top-bar {
  position: absolute;
  width: 100%;

  &.desktop {
    position: relative;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.rtl {
  font-family: var(--font-hebrew);
}
</style>
