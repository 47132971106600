import paymentSettingsService from '@/modules/paymentSettings/service/paymentSettingsService';

const state = {
  paymentSettings: {},
};

const getters = {
  paymentSettings: (state) => state.paymentSettings,
  currency: (state) => state.paymentSettings?.currency,
  taxMode: (state) => state.paymentSettings?.tax_mode,
  isPrimaryPaymentGatewayConnected: (state) => state.paymentSettings?.connected_payment_gateways?.includes(
    state.paymentSettings?.payments_gateway_type,
  ),
};

const mutations = {
  setPaymentSettings(state, paymentSettings) {
    state.paymentSettings = { ...state.paymentSettings, ...paymentSettings };
  },
};

const actions = {
  async fetchSettings({ commit }) {
    const settings = await paymentSettingsService.fetchSettings();
    commit('setPaymentSettings', settings);
  },
  async saveSettings({ commit }, settings) {
    const saveSettingsResponse = await paymentSettingsService.saveSettings(settings);
    commit('setPaymentSettings', settings);
    return saveSettingsResponse;
  },
  updateDefaultCurrency() {
    return paymentSettingsService.updateDefaultCurrency();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
