import httpService from '@/modules/common/services/httpService';

const gatewayService = httpService.create(window.pov_config.VUE_APP_API_URL_GATEWAY);
const channelsBasePath = 'business/messaging/v1/channels/';
const baseRoute = 'v3/communication';

const getCallsSettings = async () => gatewayService.get(`${baseRoute}/voice_calls/settings`).then((res) => res.data);

const createCallsSettings = async (settings) => gatewayService.post(`${baseRoute}/voice_calls/settings`, settings).then((res) => res.data);

const updateCallsSettings = async (settings) => gatewayService.put(`${baseRoute}/voice_calls/settings`, settings).then((res) => res.data);

const getRemainingQuota = async () => gatewayService.get(`${baseRoute}/quotas/current`).then((res) => res);

const getBusinessActiveDedicatedNumber = async () => {
  const result = await httpService.get(channelsBasePath, { new_api: true, provider: 'nexmo' });
  return result.data.data.channels?.find((number) => number.number_type === 'transactional');
};
const toggleTextingStatus = async (status) => httpService
  .put('platform/v1/numbers/dedicated_numbers/set_two_way_texting_status', { status })
  .then((res) => res);

const claimNumber = async () => httpService.post(`${channelsBasePath}`, { new_api: true, channel: { provider: 'nexmo' } })
  .then((res) => res.data?.number);

export default {
  getCallsSettings,
  createCallsSettings,
  updateCallsSettings,
  getRemainingQuota,
  getBusinessActiveDedicatedNumber,
  toggleTextingStatus,
  claimNumber,
};
