import appService from '@/modules/app/services/appService';

const state = {
  billingApp: undefined,
};

// TODO: is there a duplication of data here? can we normalize it so that a billing app is just a getter?
const mutations = {
  setBillingApp(state, app) {
    state.billingApp = app;
  },
};

const actions = {
  async loadBillingAppData({ commit, state }, businessUid) {
    if (!state.billingApp && !state.billingApp?.uid) {
      const result = await appService.getAppsByType({ type: 'billing_app', businessUid });
      if (result.code !== 'error') {
        commit('setBillingApp', result.apps[0]);
      }
    }
  },
};

const getters = {
  billingApp: (state) => state.billingApp,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
