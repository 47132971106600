import httpService from '@/modules/common/services/httpService';

const viewsRoute = '/business/search/v1/views';
const filtersRoute = '/business/search/v1/view_filters';

const getViews = async () => httpService.get(viewsRoute)
  .then((res) => res.data?.data);

const getFilters = async () => httpService.get(filtersRoute)
  .then((res) => res.data?.data);

const updateViews = async (views) => httpService.post(`${viewsRoute}/bulk`, { views })
  .then((res) => res.data);

const createView = async (view) => httpService.post(`${viewsRoute}`, { view })
  .then((res) => res.data);

const updateView = async (uid, view) => httpService.put(`${viewsRoute}/${uid}`, { view })
  .then((res) => res.data);

const deleteView = async (uid) => httpService.remove(`${viewsRoute}/${uid}`, { uid })
  .then((res) => res.data);

const getAvailableColumns = async () => httpService.get(`${viewsRoute}_columns`)
  .then((res) => res.data?.data);

const getSearchFiltersObject = (filters) => {
  const searchFilters = { search_filter: {} };
  if (filters.requires_payment) {
    searchFilters.search_filter.matter_metadata_flat = {
      'payments.open': {
        range: {
          gte: 1,
        },
      },
    };
  }
  if (filters.last_client_activity_filter) {
    searchFilters.search_filter.last_client_activity_filter = filters.last_client_activity_filter;
  }
  if (filters.created_at_filter) {
    searchFilters.search_filter.created_at_filter = filters.created_at_filter;
  }
  if (filters.client_data_associated_with_field) {
    searchFilters.search_filter.client_data_associated_with_field_filter = {};
    Object.keys(filters.client_data_associated_with_field).forEach((key) => {
      searchFilters.search_filter.client_data_associated_with_field_filter[key] = filters.client_data_associated_with_field[key] || '';
    });
  }
  return searchFilters;
};

export default {
  getViews,
  getFilters,
  updateViews,
  createView,
  updateView,
  deleteView,
  getAvailableColumns,
  getSearchFiltersObject,
};
