import formattingUtil from '@/utils/formattingUtil';

export const TAX_MODES = {
  include: 'include',
  exclude: 'exclude',
};

function calcTaxModeTotals(taxMode, priceAmount, taxes) {
  const price = Number(priceAmount);
  let netTotal = price;
  if (taxMode === TAX_MODES.include) {
    const taxTotalRate = taxes.reduce((acc, tax) => acc + tax.rate, 0);
    netTotal = formattingUtil.roundNumber(price / (1 + taxTotalRate / 100));
  }

  const taxTotal = taxes.reduce((acc, tax) => {
    const taxAmount = formattingUtil.roundNumber(netTotal * (tax.rate / 100));
    return acc + taxAmount;
  }, 0);

  let grandTotal = price;
  if (taxMode === TAX_MODES.exclude) {
    grandTotal = price + taxTotal;
  } else {
    netTotal = price - taxTotal;
  }

  return { netTotal, taxTotal, grandTotal };
}

function getPriceByTaxMode(taxMode, netTotal, grandTotal) {
  if (taxMode === TAX_MODES.exclude) {
    return netTotal;
  }
  return grandTotal;
}

function generateLocalID() {
  return Math.floor(Math.random() * (100000 - 1000 + 1)) + 1000;
}

export default {
  TAX_MODES,
  calcTaxModeTotals,
  getPriceByTaxMode,
  generateLocalID,
};
