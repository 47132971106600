const getPayload = (message, data) => {
  const payload = { source: 'pov-wrapper', event: message };

  if (data) {
    payload.data = data;
  }

  return payload;
};

const post = (message, data) => {
  window.parent.postMessage(getPayload(message, data), '*');
};

const postOpener = (message, data) => {
  window.opener.postMessage(getPayload(message, data), '*');
};

export default {
  post,
  postOpener,
};
