/* eslint-disable */
import pendoService from '@/modules/externalScripts/services/pendoService';
import datadogService from '@/modules/externalScripts/services/datadogService';
import fullstoryService from '@/modules/externalScripts/services/fullstoryService';
import newrelicService from '@/modules/externalScripts/services/newrelicService';
import externalScriptsUtils from '@/modules/externalScripts/services/externalScriptsUtils';
import ThryvTealiumScript from '@/modules/externalScripts/scripts/thryvTealiumScript';
import ScriptInjectionUtils from '@/modules/externalScripts/utils/scriptInjectionUtils';

const userActivityPackage = 'https://d1e7vhqfrchhaw.cloudfront.net/pov/vendor-js/user_activity_package.js';
const setupPendo = (data, pendoAPIKey = {}) => {
  pendoService.buildPendo(pendoAPIKey);
  pendoService.initPendo(data);
};

const state = {
  pendoData: {},
  tealiumData: {},
  fullstoryData: {},
};

const getters = {
  pendoData: (state) => state.pendoData,
  fullstoryData: (state) => state.fullstoryData,
};

const mutations = {
  setPendoData(state, newValue) {
    state.pendoData = newValue;
  },
  setTealiumData(state, tealiumData) {
    state.tealiumData = tealiumData;
  },
  setFullstoryData(state, fullstoryData) {
    state.fullstoryData = fullstoryData;
  },
};

const actions = {
  async initExternalScripts({ dispatch, commit, rootGetters }) {
    dispatch({ type: 'initNewrelic' });

    if (rootGetters['BusinessStore/hasFeature']('add_datadog')) {
      datadogService.initDatadog(window.pov_config.NODE_ENV);
    }

    if (rootGetters['BusinessStore/hasFeature']('enable_fullstory')) {
      fullstoryService.buildFullstory(state.fullstoryData);
    }

    if (rootGetters['BusinessStore/hasFeature']('session_timeout')) {
      const setUserActivity = function () {
        commit('AuthStore/setUserActivity', Date.now(), { root: true });
      };
      const onload = setTimeout(() => { window.idleUserPackage.setUserActivityData(10000, setUserActivity); }, 2000);
      externalScriptsUtils.addScriptWithSrc('userActivityPackage', userActivityPackage, {}, onload);
    }
  },
  async initNewrelic({ rootGetters }) {
    const isApp = rootGetters['CommonStore/isMobileApp'];
    newrelicService.addNewrelicMetaData(isApp);
  },
  async SetProfitwell(arg, data) {
    externalScriptsUtils.addScript('profitwell-js', data.script, { 'data-pw-auth': data.authToken });
    setTimeout(() => {
      if (typeof window.profitwell === 'function') {
        window.profitwell('start', { user_id: data.userId });
      }
    }, 0);
  },
  // Can be called from Angular to set pendo data
  async setPendoData({ commit, rootGetters }, data) {
    commit('setPendoData', data);

    const isMobile = rootGetters['CommonStore/isMobile'] || rootGetters['CommonStore/isMobileApp'];
    const pendoAPIkey = isMobile ? data.pendo.mobile : data.pendo.desktop;

    // const pendoAPIkey = 'ab65ff25-7e59-41ce-4c10-62029dddc256';
    setupPendo({ visitor: data.visitor, account: data.account }, pendoAPIkey);
  },

  initTealiumData({ commit }, tealiumData) {
    const tealiumScriptAsString = ThryvTealiumScript.getScriptAsString(tealiumData);
    commit('setTealiumData', tealiumData);
    ScriptInjectionUtils.injectScriptToMainDom(tealiumScriptAsString, 'thryv_tealium');
  },

  setFullstoryData({ commit, rootGetters }, data) {
    commit('setFullstoryData', data);
    fullstoryService.setIdForFullstory(data.uid, data.properties)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
