import httpService from '@/modules/common/services/httpService';

const paymentSettingsRoute = '/platform/v1/payment/settings';
const settingsRoute = '/v2/settings';

const fetchSettings = async () => httpService.get(paymentSettingsRoute).then((res) => res.data?.data?.payment_settings);
const saveSettings = async (payload) => httpService.put(settingsRoute, payload).then((res) => res);
const updateDefaultCurrency = async () => httpService.post(`${settingsRoute}/update_default_currency`, {}).then((res) => res.data);
export default {
  fetchSettings,
  saveSettings,
  updateDefaultCurrency,
};
