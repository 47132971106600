import httpService from '@/modules/common/services/httpService';

const baseRoute = '/business/accounts/v1';

const fetchBranding = async () => httpService.get(`${baseRoute}/branding`)
  .then((res) => res.data?.data.branding);

const fetchAttributes = async () => httpService.get(`${baseRoute}/attributes`)
  .then((res) => res.data?.data.attributes);

const fetchFeaturesFlags = async (businessUid) => httpService.get(`platform/v1/businesses/${businessUid}/features`)
  .then((res) => res.data?.data);

const fetchBusiness = async (businessUid) => httpService.get(`platform/v1/businesses/${businessUid}`)
  .then((res) => res.data?.data);

const updateBusiness = async (businessUid, payload) => httpService.put(`v2/settings/businesses/${businessUid}`, payload)
  .then((res) => res.data);

export default {
  fetchBranding,
  fetchAttributes,
  fetchFeaturesFlags,
  fetchBusiness,
  updateBusiness,
};
