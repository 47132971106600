import reserveWithGoogleService from '@/modules/reserveWithGoogle/service/reserveWithGoogleService';

const RGW_STATUS = {
  CONNECTED: 'redirect_connected',
  PAID_NOT_OPTED_IN: 'paid_not_opted_in',
  REDIRECT_NOT_CONNECTED: 'redirect_not_connected',
};

const state = {
  services: [],
  isRwgConnected: false,
  didFetchServices: false,
  rwgStatus: '',
  isOnlineSchedulingEnabled: false,
  businessDetails: {
    name: '',
    category: '',
    address: '',
    phone: '',
  },
};

const getters = {
  businessDetails: (state) => state.businessDetails,
  services: (state) => state.services,
  isRwgConnected: (state) => state.isRwgConnected,
  isOnlineSchedulingEnabled: (state) => state.isOnlineSchedulingEnabled,
  rwgStatus: (state) => state.rwgStatus,
  didFetchServices: (state) => state.didFetchServices,
};

const mutations = {
  setBusinessDetails(state, businessDetails) {
    state.businessDetails = businessDetails;
  },
  setBusinessDetail(state, { value, detail }) {
    state.businessDetails[detail] = value;
  },
  setIsRwgConnected(state, isRwgConnected) {
    state.isRwgConnected = isRwgConnected;
  },
  setServiceList(state, serviceList) {
    state.services = serviceList;
  },
  setIsOnlineSchedulingEnabled(state, isOnlineSchedulingEnabled) {
    state.isOnlineSchedulingEnabled = isOnlineSchedulingEnabled;
  },
  setRwgStatus(state, rwgStatus) {
    state.rwgStatus = rwgStatus;
  },
  setDidFetchServices(state, didFetchServices) {
    state.didFetchServices = didFetchServices;
  },
};

const actions = {
  async loadBusinessDetails({ commit }) {
    const rwgStatus = await reserveWithGoogleService.getBusinessRwgStatus();
    commit('setRwgStatus', rwgStatus.rwg_status);
    commit('setIsRwgConnected', rwgStatus.rwg_status === RGW_STATUS.CONNECTED);

    const businessSettings = await reserveWithGoogleService.getBusinessSettings();
    commit('setIsOnlineSchedulingEnabled', businessSettings.scheduling_enabled);
  },
  updateBusinessDetail({ commit }, { value, detail }) {
    commit('setBusinessDetail', { value, detail });
  },
  async loadServices({ commit }, businessUid) {
    const categoriesData = await reserveWithGoogleService.getServicesCategories(businessUid);
    const { categories } = categoriesData.data.data;
    const servicesData = await Promise.allSettled(
      categories.map((category) => reserveWithGoogleService.getAllServicesOfCategory(category.id, businessUid)),
    );
    const services = [].concat(...servicesData.map((service) => service.value));
    commit('setServiceList', services);
    commit('setDidFetchServices', true);
  },
  async submitOptIn({ state, commit }) {
    const params = {
      scheduling_enabled: true,
      redirect_business_name: state.businessDetails.name,
      redirect_business_category: state.businessDetails.category,
      redirect_business_address: state.businessDetails.address,
      redirect_business_phone: state.businessDetails.phone,
    };

    const data = await reserveWithGoogleService.submitReserve(params);
    if (data.rwg_status === RGW_STATUS.CONNECTED) {
      commit('setRwgStatus', data.rwg_status);
      commit('setIsRwgConnected', true);
      return 'success';
    }
    return 'error';
  },
  async optOut({ commit }) {
    const params = {
      disable_rwg_redirect: true,
    };
    const data = await reserveWithGoogleService.submitReserve(params);
    if (data.rwg_status === RGW_STATUS.PAID_NOT_OPTED_IN) {
      commit('setRwgStatus', data.rwg_status);
      commit('setIsRwgConnected', false);
      return 'success';
    }
    return 'error';
  },
  updateIsOnlineSchedulingEnabled({ commit }, { value }) {
    commit('setIsOnlineSchedulingEnabled', value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
